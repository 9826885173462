/*footNavi*/
.wrapFootNaviArea {
    padding-bottom: 15px;
    background-color: rgb(0,0,0,.85)!important;
}
.footNaviArea {
    display: flex!important;
    flex-wrap: wrap;
    width: 90%;
    margin: 0 auto;
}
.footNaviArea li.footNavi {
    width: calc(100% / 5);
    text-align: center;
}
.footNaviArea li.footNavi a svg,
.footNaviArea li.footNavi a button svg {
    width: 1.5rem;
    height: 1.5rem;
}


.linkDisabled {
    pointer-events: none;
  }
